.market_section {

    display:flex;
    flex-direction:column
} 

.market_titre {
    font-size: 8rem;
    font-weight: 900;
    text-align: center;
    margin-top: 6rem;
}

.market_titre :nth-child(1) {
    color: #000;
    -webkit-text-stroke: 2px #E8C133;
    padding-right: 2rem;

}

.market_titre :nth-child(2) {
    color: #fff;
    
}

.Bloccentral {
    display: flex;

    gap: 1rem;
    justify-content: center;
}

.Bloccentral :nth-child(2) {
   display:flex;
   flex-direction:column;
   gap:1rem;
   justify-content:center;
   align-content:space-between;
}

.parentDownloadButton {
    display:flex;
    gap:2rem
}

.DownloadButton {
    background: rgba(255, 255, 255, 0.2);
    padding: 1rem 2rem;

    /*width: 20rem;*/
    width :280px;
    border-radius: 5rem;
}

.DownloadButton:hover{
    background: rgba(232, 193, 51, 0.5);
 
    border-radius: 5rem;

    transition: all ease 0.5s;
}

.Heading_market {
    font-size: 3rem;
    line-height: 3rem;
}

.Heading_market_1 {
    display: block;
    margin-bottom: 1rem;
}
.Heading_market :nth-child(2) {
    font-size: 3.8rem;
    font-weight: 900;
    color: #E8C133;

    display: block;
    margin-bottom: 1rem;
}

.market_p {
    margin-bottom: 4rem;
}


#PortailWeb {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;


}

#PortailWeb a {
    background-color: #E8C133;
    color: #000;
    padding: 1rem 4rem;
    border-radius: 2rem;
    font-weight: 700;
    transition: all 0.3s;
}

#PortailWeb a:hover {
  
    padding: 1rem 4.5rem;
    border-radius: 1rem;
    transition: all 0.3s;
}


/*Media queries desktop*/
@media screen and (min-width:1440px){ 

}

@media screen and (width<1440px)  and (width>600px) { 
    .market_titre {
        font-size: 8rem;
        line-height: 7rem;
        margin-bottom: 2rem;
    }
    .market_titre span {
        display: block;
    }


}

@media screen and (max-width:600px){ 

    .market_titre {
        font-size: 5rem;
        line-height: 5rem;
        margin-bottom: 1rem;
      
    }
    .market_titre span {
        display: block;
        
    }

    .Bloccentral {
        display: flex;
        flex-direction: column;
    
        gap: 1rem;
        justify-content: center;
        text-align: center;
    }
    .parentDownloadButton {
        flex-direction: column;
        align-items: center;
    }
    
}