#Membre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /*width: 40%;*/
    
   
}

#Membre span {
    color: #E8C133;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}
#Membre div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    
    
}


/*Media queries desktop*/
@media screen and (min-width:1440px){
    
    #Membre {
        
    
       width: 270px; /**/
        
       
    }
}
/*Media queries tablette*/
@media screen and (width<1440px) and (width>600px) {
    #Membre {
        
        width: 250px;
        
       
    }
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {

    #Membre {
        
        width: 250px;
        
       
    }
    
    #Membre img {
        width: 95%;
    }

}