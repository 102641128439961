#PrintCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    width: 20rem;
   

}


#PrintCard span {
   display: inline-block;
   width: 8rem;
   text-align: center;
}
/*Media queries mobile*/
@media screen and (max-width:600px){

    #PrintCard {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        width: 10rem;
       
    
    }

}