.section1 {
    display: flex;
    gap: 1rem;
}

h1 span {
    display: contents;
}

.section1 .blocImg {
    position: relative;
    width: 100%;
   
}
.section2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.Titre_bloc {
   
    color: #fff;
    font-weight: 900;
    letter-spacing:1rem;
    line-height: 10rem;

    position:absolute;
    margin-left: 4rem;
    z-index: 1;  
    
   
 
}
.Titre {
   
    -webkit-text-stroke: 2px #E8C133;
    color: #000;
  
}



.section2 div:nth-child(2){
   margin: 15rem auto 0;
 }
 
 .section2 div:nth-child(2) img {
    margin: 0 auto;

 }

.section3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}
.section3 .heading1 {
    color: #E8C133;
    font-weight: 300;

}
.section3 .heading1 span {
    color: #fff;
    font-weight: 900;
}
.section3 div:nth-child(2) {
    width: 40%;
}
.section4 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.container_ts {
    display: flex;
    gap: 2rem;

  
}
.container_ts .bloc:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-end;
    
}


.container_ts .bloc:nth-child(2) div:nth-child(1),.container_ts .bloc:nth-child(2) div:nth-child(3)  {
    margin: 0 auto 0 2rem;

}

/*.container_ts .bloc:nth-child(2) div:nth-child(1),.container_ts .bloc:nth-child(2) div:nth-child(3)  {
    align-self: flex-start;

}
.container_ts .bloc:nth-child(2) div:nth-child(2),.container_ts .bloc:nth-child(2) div:nth-child(4)  {
    align-self: flex-end;

}*/


.bloc_type_site {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    
}
.Puce_invisible {
    display: none;
}
.bloc_type_site h1 {
    color: #E8C133;
    font-weight: 300;
    

}
.bloc_type_site h1  span{
    color: #fff;
    font-weight: 900;

}


.list_element {

    display: flex;
    gap: 1rem;
}

.list_element div {
    width: 60%;
}

.site_type{
    color: #E8C133;
    font-weight: 900;
}

.section5 .Titre_bloc br {
    display: contents;
}

.section5 .Titre_bloc {
    position: relative;
}

.Dev_bloc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
    
}

.Dev_bloc .heading1 {
    font-weight: 300;

    color: #E8C133;
}

.Dev_bloc .heading1 span {
    color: #fff;
    font-weight: 900;
}

.Dev_bloc .bloc img {
    margin: 0 auto;
}



.Card_lang_roster {
    margin: 4rem 0rem ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.Card_lang {
    width: 18rem;
    height: 20rem;
    background-color:#2A2A2A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    border-radius: 2rem;
    padding:2.5rem;
}

.Card_lang h1 {
    color: #E8C133;
    font-size: 2rem;
}

.Card_lang div {
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.section6 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    
}

.section6 .bloc:nth-child(1) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
   
}

.section6 .bloc:nth-child(1) div {
    padding-right: 2rem;
}

.section6 .bloc:nth-child(1) h1 {
    color: #E8C133;
    font-weight: 300;
}


.section6 .bloc:nth-child(1) h1 span {
    color: #fff;
    font-weight: 900;
}



/*Media queries desktop*/
@media screen and (min-width:1440px){
    .section1 .blocText {
        width:65%;
       
    }

    .section1 .blocText  p {
        width:65%
    }

    .section1 .blocImg  div {
        position: absolute;
       
       
    }
    .section1 .blocImg  div:nth-child(2) {
  
        bottom: -5rem;
        left: 6rem;
       
    }
    .Titre_bloc {
        font-size: 10rem;
        color: #fff;
     
    }

    .Card_lang_roster {
        margin: 4rem 0rem ;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 2rem;
    }
    
}

/*Media queries Tablettes*/
@media screen and (width<1440px)  and (width>600px) {
    .section1 .blocText {
        width:90%;
       
    }

    .section1 .blocText  p {
        width:95%
    }

    .section1 .blocImg  div {
        position: absolute;
       
       
    }
    .section1 .blocImg  div:nth-child(2) {
  
        bottom: -3rem;
        left: 3rem;
       
    }
    .Titre_bloc {
        font-size: 8rem;
        color: #fff;
     
    }
    .section5 .Titre_bloc br {
        display: block;
    }
    
}

/*Media queries mobile*/
@media screen and (max-width:600px){
    .section1 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
       
    }

    .section1 h1 {
        text-align: center;
    }
    .section1 h1 br {
        display: contents;
    }
    .section1 .blocText {
       display: contents;
    }

    .section1 div:nth-child(1) p {
        order: 1;
        text-align: center;
        margin-bottom: 1rem;

    }
    .section1 div:nth-child(1) div[id='DevisButton'] {

        order: 1;

    }

    .section1 .blocImg {
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
   
    .section1 .blocImg div:nth-child(1) {
        position: absolute;
        top: 0rem;
       
       
    }
    .section1 .blocImg div:nth-child(2) {
        margin-top: 12rem;
        margin-bottom: 1rem;
        z-index: 1;
       
       
    }
    .section5 .Titre_bloc br {
        display: block;
    }
    .Dev_bloc {
        
        gap: 2rem;
    }
    .Titre_bloc {
        font-size: 5rem;
        color: #fff;
        letter-spacing:0.2rem;
        line-height: 5.5rem;

        position:relative;
        margin-left: auto;
        z-index: 1;  
        
       

     
    }

    .section2 div:nth-child(2){
        margin: 2rem auto 0;
      }

    .section3 {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
    }
      .section3 div:nth-child(1) {
        width: 25%;
    }
    .section3 .heading1 {
        color: #E8C133;
        font-weight: 300;
        width: 70%;
    
    }
  
    .section3 div:nth-child(2) {
        display: contents;
    }

    .section4 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        flex-wrap: wrap;
       
    }
    #Puce {
        display: none;
    }

    .container_ts {
        display: flex;
        gap: 2rem;
        flex-direction: column;
    
      
    }
    .container_ts .bloc,.container_ts .bloc:nth-child(2)  {
        display: contents;
 
    }

    .container_ts img {
        margin: auto;
    
    }
    .bloc_type_site div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        flex-wrap: wrap;

    }   
    .Puce_invisible {
        display: block;
        width: 20%;
    }

    .bloc_type_site div h1 {
       width: 75%;

    }
 
    .list_element:nth-child(1) {
        width: 20%;
    }


    .list_element div {
       display: contents;
    }
  

    .list_element p {
        width: 85%;
        margin-left: auto;
     }

    .zerotext {
        order: 0;

    }
    .untext {
        order: 2;
        
    }
    .deuxtext {
        order: 4;
        
    }
    .troistext {
        order: 6;
        
    }

    .zeroimg {
        order: 1;

    }
    .unimg {
        order: 3;
        
    }
    .deuximg {
        order: 5;
        
    }
    .troisimg {
        order: 7;
        
    }
    .section5 .Titre_bloc {
        text-align: center;
        margin-bottom: 1rem;
        letter-spacing: normal;
    } 
    .Dev_bloc div:nth-child(1){
        display: contents;
    }
    .Dev_bloc div:nth-child(1) p{
        order : 1;
    }
    .Dev_bloc div:nth-child(2){
        order: 0;
    }

    .section6 {
       
        gap: 1rem;
        justify-content: flex-start;
       
    }

    .section6 .bloc:nth-child(1){
        display: contents;
        
    }

    .section6 .bloc:nth-child(1) h1 {
     width: 70%;
        
    }

    .section6 .bloc:nth-child(1) p {
       order: 2;
       width: 80%;
       margin-top: 2rem;
       margin-left: auto;
           
       }
    .section6 .bloc:nth-child(1) div{
        display: contents;
        
    }
    
    .section6 div:nth-child(2){
        order: 1;
    }
}