
a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

img,picture,video {
  display: block;
  max-width: 100%;
 
}


.ToggledClass {
  transform: rotate(90deg);
  transition: ease all 0.5s
}

.nonToggledClass {
  transform: rotate(0deg);
  transition: ease all 0.5s
}
.paragraphClass {

  color: #fff;
 
  height: 8rem;
  opacity: 1;

  transition: all ease 0.5s , opacity ease 0.2s;

  padding: 1rem 0rem 0rem 4rem;
 

}


.nonparagraphClass {

  color: #fff;

  height: 0;
  opacity: 0;

  padding: 0rem 0rem 0rem 4rem;

  transition: all ease 0.5s , opacity ease 0.2s;
 

}

/* Magical adaptative Font-size  à tweak plus tard
* {
  font-size: calc(0.5rem + 1.5625vw)
}*/


 /*DEFINITION DU CONTENEUR SUIVANT LES ECRANS */

/*Media queries desktop Old 
@media screen and (min-width:1440px){
  .container {
    max-width: 1236px;
    margin: 0 auto;
    padding:1rem 2rem;
   
  }

}*/


/*Media queries desktop*/
@media screen and (min-width:1440px){
  .container {
    max-width: 1440px;
   
    padding:1rem 2rem;
   
  }

  section {

    margin: 0 auto 4rem;

  }

 .heading_span {
    display: flex;
    margin: 0 0 1rem;
    font-size: 1.75rem;
    line-height:1.75rem; 
    /*font-size:calc(0.5rem + 2vw) ;
    line-height:calc(0.5rem + 2vw) ;*/
  }

  .heading1 {
    display: flex;
    margin: 0 0 2rem;
    font-size: 3.5rem;
    line-height:3.5rem; 
    /*font-size:calc(0.5rem + 3vw) ;
    line-height:calc(0.5rem + 3vw) ;*/
  }

  .paragraphe {
    margin: 0 0 1rem;
   
  }


}
/*Media queries Tablettes*/
@media screen and (width<1440px)  and (width>600px) {
  .container {
  
    max-width:100%;
    padding:1rem 1rem;
  
  }

  section {

    margin: 0 auto 3rem;

  }

  
  .heading_span {
    display: flex;
    margin: 0 0 1rem;
  }
  
  .heading1 {
    display: flex;
    margin: 0 0 1rem;
  
  }
  
  .paragraphe {
      margin: 0 0 0.5rem;
  }

  
}

/*Media queries mobile*/
@media screen and (max-width:600px){
  .container {
    max-width: 100%;
    padding:1rem 1rem;
  
  }
  
  section {

    margin: 0 auto 2rem;

  }

  .heading_span {
    display: flex;
    font-size: 1.5rem;
    line-height:1.5rem; 
    /*font-size:calc(0.5rem + 4vw) ;
    line-height:calc(0.75rem + 4vw) ;*/
    margin: 0 0 1rem;
  }
  
  .heading1 {
    display: flex;
    font-size: 2.25rem;
    line-height:2.25rem; 
    /*font-size:calc(0.5rem + 8vw) ;
    line-height:calc(0.75rem + 8vw) ;*/
    margin: 0 0 1rem;
  }
  
  .paragraphe {
    margin: 0 0 0.5rem;
  }

 
}


/*

.desktoplimit {
  
  max-width: 1440px;
  margin: 0 auto;
  padding:1rem 1rem 2rem;
 

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*/