.hide {
    position: absolute;
    top: 0;
    left: -100vw;
    opacity: 1;
    transition: ease-in-out 0.5s;


}

.show {
    top: 0;
    left: 0;
    opacity:1;
    transition: ease-in-out 0.5s;
}

#Menu {
    width: 100vw;
    height: 100vh;
    z-index:-1;

    display: flex;
   
}

.MenuLeftConteneur{
   background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

.MenuRightConteneur{
    background:linear-gradient(#FBD13B,#735A03);
    width: 50%;

    position: relative;
    
 
 }
 
#MenuLeftDIv ul {
    list-style: url(../../assets/icons/puce.svg);
    font-size: 1.2rem;
    padding-left: 2.7rem;
    width: fit-content;

}

#MenuLeftDIv ul li:hover {

    list-style: url(../../assets/icons/puce_hover.svg);
    font-weight:700;
    
}
    #MenuLeftDiv li {
    margin-bottom: 4rem;
    cursor: pointer;
}

#MenuRight {
    /*background-color: blue;*/
    display: flex;
    justify-content: center;
    
}

/*Media queries desktop*/
@media screen and (min-width:1440px){

    .MenuLeftConteneur, .MenuRightConteneur {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

#MenuLeftDIv li {
    margin-bottom: 2rem;
    cursor: pointer;
}

#secondebloc {
    margin-top: 6rem;
}




} 
/*Media queries Tablettes*/
@media screen and (max-width:1440px) and (min-width:600px) {

    #MenuCentre {
        display: block;
        width: 100%;
        background-color: #000;
        margin-top: 4rem;
    }
    .MenuLeftConteneur,.MenuRightConteneur {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
       
    }
    
    #MenuLeftDIv li {
        margin-bottom: 2rem;
        cursor: pointer;
    }

    #secondebloc {
        margin-top: 6rem;
    }

    


}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
    #Menu {
       
        display: flex;
        flex-direction: column;
        
       
    }

    .MenuLeftConteneur{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    #MenuLeftDIv li {
        margin-bottom: 1rem;
        cursor: pointer;
    }
    

    #secondebloc {
        margin-top: 3rem;
    }

    
    .MenuRightConteneur {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        left: 0;  
        
        
       
       
    }

    .devant {
         
        /*background:rgba(115, 91, 3, 0.8);
        background: #E8C133;*/
        background:linear-gradient(#FBD13B,#735A03);
        pointer-events:initial;

    }

    .derriere {
        background: transparent;
        pointer-events:none;

        opacity: 0;

    }
    
    #MenuRight {
        /*background-color: blue;*/
        display: flex;
        justify-content: center;
      
        
    }

}
/*Media queries Mobile landscape oriented*/
@media screen and (min-width:600px) and (max-height:600px) and (orientation:landscape) {

    #Menu {
    width:100%;
    min-height: 120vh;
    z-index:-1;
    display: flex;
   
    }
    .hide {
    position: absolute;
    top: 0%;
    left: -100%;
    opacity: 1;
    transition: ease-in-out 0.5s;


}

.show {
    top: 0%;
    left: 0%;
    opacity:1;
    transition: ease-in-out 0.5s;
}



   #MenuLeftDIv li {
        margin-bottom: 1rem;
        cursor: pointer;
    }

    #secondebloc {
        margin-top: 3rem;
    }




}


