.barreTop {

    d:path('M29.7937 6.94748L17.9477 18.7935C17.4243 19.3169 17.4282 20.1694 17.9564 20.6976C18.4846 21.2258 19.337 21.2297 19.8604 20.7063L31.7065 8.86023C32.2299 8.33684 32.226 7.48436 31.6978 6.95617C31.1696 6.42798 30.3171 6.42409 29.7937 6.94748Z');
    
    }
    
    .barreBottom {
    
    d:path('M31.7068 18.7937L19.8608 6.94768C19.3374 6.42429 18.4849 6.42818 17.9567 6.95637C17.4285 7.48456 17.4246 8.33704 17.948 8.86043L29.7941 20.7065C30.3175 21.2299 31.1699 21.226 31.6981 20.6978C32.2263 20.1696 32.2302 19.3171 31.7068 18.7937Z'
    );

}