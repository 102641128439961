#MenuRightDivConnexion {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: #000;

    z-index: 2;
   
}

#MenuRightDivConnexion form span {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}


#email,#password {

    outline: none;
    border:none;
    width: 100%;
    background-color: #000;/**/
    color: #fff;
    font-size: 1rem;

}

#email {
    border-radius: 1rem 1rem 0rem 0rem;
    
}

#password {
    border-radius: 0rem 0rem 1rem 1rem;
    
}

#email::placeholder,#password::placeholder{
    color: #8d8d8d;
  
    
}

#password::-ms-reveal {
    filter: invert(100%);
}


#emaildiv,#passworddiv{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

 }

#emaildiv{
    margin-bottom: 0.5rem;
}

#passworddiv {
    margin-bottom: 1rem;
}

#emaildiv img,#passworddiv img{
    position: absolute;
    display: block;
    left: 1.5rem;
 }

#Connexion {
    outline: none;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    padding: 1rem 0rem ;
    width: 100%;
    border-radius: 0.7rem;
    font-size: 1rem;
    text-align: center;
}

#Connexion:hover{
    filter: invert(100%);
}

#connexionmethodefacebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin-top: 3rem;

}

#connexionmethodefacebook button{
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
    text-align: right;
    background-color: #0007;
    color: #fff;

}

#connexionmethodegoogle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin-top: 1rem;

}


#connexionmethodegoogle button {

    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
    text-align: right;
    background-color: #0007;
    color: #fff;

}

#connexionmethodegoogle img, #connexionmethodefacebook img {
    display: block;
   
    height: 100%;
}
#connexionmethodefacebook:hover, #connexionmethodegoogle:hover {
    filter: invert(100%);
}

/*Media queries desktop*/
@media screen and (min-width:1440px){
    #email,#password {

       
        padding: 1.5rem 2rem 1.5rem 4rem;
       
    }

}
/*Media queries Tablettes*/
@media screen and (max-width:1440px) and (min-width:600px) {

    #email,#password {

       
        padding: 1.5rem 2rem 1.5rem 4rem;
       
    }


}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
    #email,#password {

        padding: 1rem 2rem 1rem 4rem;
       
    }


}