
#boite {
 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    
   /* border: 3px solid #E8C133;
    border-radius: 1rem;
    z-index: 3;*/

    inset: 0;
    border: 0px solid #E8C133;
    border-radius: 0rem;
    text-align: center;
    z-index: 4;

    

    transition: ease-in-out 0.3s;
}

#boite button {
    background-color: #E8C133;
    border-radius: 1rem;
    padding: 1rem 4rem;
    font-weight: 700;
    transition: ease 0.3s;
    
}

#boite button:hover {
    color: #fff;
    padding: 1rem 3.5rem;
    transition: ease 0.3s;
    
}

.hideconfirmation {
opacity: 0;
transition: ease-in-out 0.1s;
pointer-events: none;
}

/*Media queries Desktop*/
@media screen and (min-width:1440px) {
    #boite {

        position: absolute;
        gap: 1rem;

        /*top:calc(50vh - 6rem);
        left:calc(50vw - 12.5rem);
        
      
        width: 25rem;
        height: 12rem;
        */
    }
    #boite span {
        margin-top: 3rem;
        text-align: center;
    }
    #boite button {
        background-color: #E8C133;
        border-radius: 1rem;
        padding: 1rem 4rem;
        font-weight: 700;
        transition: ease 0.3s;
        
    }
    #boite button:hover {
        color: #fff;
        padding: 1rem 3.5rem;
        transition: ease 0.3s;
        
    }
  
    

}
/*Media queries Tablette*/
@media screen and (max-width:1440px) and (min-width:600px) {
    #boite {

       position: absolute;
       gap: 1rem;


        /* top:calc(50vh - 6rem);
        left:calc(50vw - 12.5rem);
        
      
    
        width: 25rem;
        height: 12rem;
       
        transition: ease-in-out 0.3s;*/
    }
  

}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
    #boite {

        
        position: absolute;
        gap: 1rem;
        padding:3rem 2rem 2rem;
       /* inset:40% 10%;*/
      
    }

    #boite button {
        
        padding: 1rem 3rem;
        
        
    }

    #boite button:hover {
    
        padding: 1rem 2.5rem;
        transition: ease 0.3s;
        
    }
   

}