/*Debut de la section header */
header {
    
    background-color: rgba(0,0, 0, 0.7);
    position:fixed/**/
    /*position: relative*/;
    top: 0;
    width: 100%;  
    z-index: 2;
    
}

nav {
  
    display: flex;
    align-items: center;
    justify-content:space-between;   
 
}

nav div {
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    gap: 1rem;
    cursor: pointer;

}

.block {
    opacity: 1;
    transition: ease-in-out 0.3s;
}

.none {
    opacity: 0;
    pointer-events: none;
    transition: ease-in-out 0.3s;
    width: 0px;
}

/*Media queries desktop*/
@media screen and (min-width:1440px){
    header {
    
       /* padding-top: 4rem;*/
       padding-top: 10px;
        
    }
    nav {
        margin: 0 auto ;
    }
  
}

/*Media queries Tablettes*/
@media screen and (width<1440px) and (width>600px){
    nav span {
        display: none;
    }
    
  }
/*Media queries Mobile*/
@media screen and (max-width:600px) {

    header {
    
        padding-top: 1rem;
        padding-bottom: 1rem; /**/
        
    }

    header nav[class='container']{
  
        padding: 0.5rem 1rem;
       
    }
    nav span {
        display: none;
        transition: ease-in-out 1s;
    }
    
     nav img {
        /*width: 80%;
        width: 125.81px;
        height: 24px;*/
        transition: ease-in-out 1s;
    }

    .contact{
        /*width: 80%;
        width: 125.81px;*/
        width: 43.48px;
        height: 24px;
        transition: ease-in-out 1s;
    }
    
    
     nav svg {
        /*width: 80%;*/
        width: 43.48px;
        height: 24px;
        transition: ease-in-out 1s;
    }
    .Logoagency {
        width: 125.81px;
        height: 40px;
    }
}






