#Contact_Popup {
    background-color: #000;
    border: #E8C133 1px solid;
    border-radius: 2rem;

    min-width: 16rem;

    position: absolute;
    top: 3.5rem;
    right: 0rem;
    transition: all ease 0.2s;
    
}
#Contact_Popup ul {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   gap: 1rem;
   padding: 2rem;
   transition: all ease 0.2s;
   
}

#Contact_Popup img {
    width: 30px;
    height: 30px;
}

#Contact_Popup ul li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 100;

    cursor: pointer;
    transition: all ease 0.2s;
}

#Contact_Popup span {
    display: block;
}

.visible {
    display: block;
}

.Notvisible {
    display: none;
}

nav div:last-child {
    position: relative;/**/
}
/*Media queries desktop*/
@media screen and (min-width:1440px){ }

/*Media queries Tablettes*/
@media screen and (max-width:1440px) and (min-width:600px) {}

/*Media queries Mobile*/
@media screen and (max-width:600px) {

    #Contact_Popup {
        background-color: #000;
        border: #E8C133 1px solid;
        border-radius: 2rem;
    
        min-width: 15rem;
    
        position: absolute;
        top: 3.5rem;
        right: 0rem;
        transition: all ease 0.2s;
        padding: 1rem 0.5rem;
        
    }
    #Contact_Popup ul {
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       align-items: flex-start;
       gap: 0.75rem;
       padding: 1rem;
       transition: all ease 0.2s;
       
    }
    
    #Contact_Popup ul li {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        font-weight: 100;

        cursor: pointer;
        transition: all ease 0.2s;
    }
    

}