#Homesection1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    background-image: url(../../assets//img/Moonslide.png);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;


}
#centre {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap:2rem;
    text-align: center;
   
}
#agence360 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#divparagraph {
    display: flex;
    flex-direction: column;
    align-items: center;
   
   
    
}
#DevisButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8C133;
    padding: 1rem 3rem;
    border-radius: 2rem;
    margin: 0rem  0rem 3.5rem 0rem ;
    color:#000;
    cursor: pointer;
    font-weight: 700;
    width: 17rem;
}
#BarreLink {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 6rem;
    border-radius:0rem 0rem 30rem 30rem ;
}
#BarreLink div {
    cursor: pointer;
    transition:ease-in-out 0.3s;
    
}
.divider {
    width:0.1rem;
    height: 8rem;
    background-color: #fff;
    border-radius: 2rem;
    
    
}
.skewX {
    display: block;
    transform: skewX(5deg);
    /*z-index:-1;*/
}
.skewX5p {
    display: block;
    transform: skewX(-5deg);
   /* z-index: -1;*/
}
#Homesection2 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bloc{
    width: 50%;
}
#Homesection2 p {
    width: 75%;  
}
#Homesection3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
#Homesection3 span,#Homesection3 h1 {
    align-self: flex-start;
}
#Homesection3 #Services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    gap: 2rem;

} 
#Homesection4 {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.un{

    margin-left: 0rem;

    
}
.deux {
    display: flex;
}
.deux img {
    margin: auto;
}
#Homesection4 p {
    width: 70%;
}
#Homesection5 {
    display: flex;
    flex-wrap: wrap;
    gap: 6rem;
    justify-content: center;
    align-items: flex-start;

}
#Homesection6 {
   
    display: flex;
    justify-content: center;
    align-items: center;

}
#Homesection6Div {
    width: 1440px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background:linear-gradient(rgba(31, 31, 31, 1),#3e3e3e);
}
#Homesection6Div div:first-child{
    width: 50%;
    padding: 0rem 0rem 0rem 3rem;
}
#Homesection6Div div:last-child{
    width: 50%;
    padding: 0rem 2rem 0rem 2rem;
}


#HomePage p {
    margin-bottom: 1rem;
}


/*Media queries desktop*/
@media screen and (min-width:1440px){

    #HomePage h1 {
        color: #E8C133;
        font-size: 3rem;
        line-height: 3rem;
        font-weight: 700;
    
    }
    #HomePage span {
    
        color:#E8C133;
        font-size: 1.75rem;
        line-height: 1.75rem;
    }

    #HomePage p {

        font-size:calc(0.5rem + 0.5vw) ;
        line-height:calc(1rem + 0.5vw) ;
    
      }
    
    #Homesection6Div div:first-child {
        font-size: 5rem;
        color:#E8C133;
        font-weight: 700;
        text-align: center;
    }


    #Homesection1 {
        width: 100%;
       
       /* height: 996px;*/
       
    }


    #centre {
       
        width: 100%;
        gap: 0rem;
       
    }
  #agence360 {
    margin-top: 12rem;
  }

    #divparagraph {

        margin-top: 2rem;
        width:850px;
        /*gap: 2rem;*/
    
    }
    #DevisButton {
      
        margin: 4rem  0rem 0rem 0rem ;
        
    }
    #BarreLink {
      margin-top: 4rem;
      width: 65%;
      /*width: 951px;*/
    }
    #BarreLink div:hover {

        padding: 0rem 0rem 2rem 0rem ;
        transition:ease-in-out 0.3s;
        
    }



    #Homesection2 p {
        width: 97%;
       
    }
    #Homesection2 div img {
        display: block;
        width: 570px;
      margin: auto;
    }
  
  
    #Homesection3 #Services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        gap:0rem;
    
    } 

    
#Homesection3 #Services div{
    
    width:585px;
    margin-bottom: 100px;
 
}

#Homesection3 div img {
    
    display: block;
    width: 486px;
    margin: auto;

}



#Homesection4 p {
    width: 95%;
}  
#Homesection5 {

    column-gap:170px;
    row-gap: 50px;


}

    #Homesection6Div{
        
        padding: 10rem 0rem;
    }

    #Homesection6{
        
        margin-bottom: 4rem;;
    }
}
/*Media queries tablettes*/
@media screen  and (width > 600px) and (width < 1440px) {
    #HomePage h1 {
        color: #E8C133;
        font-size: 3rem;
        line-height: 3rem;
        font-weight: 700;
    
    }
    #HomePage span {
    
        color:#E8C133;
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
    #HomePage p {

        font-size:calc(0.75rem + 0.5vw) ;
        line-height:calc(1.25rem + 0.5vw) ;
        
      }
    
    #Homesection6Div div:first-child {
        font-size: 3rem;
        color:#E8C133;
        font-weight: 700;
        text-align: center;
    }
    #HomePage h1 br {
        display: contents;
    }
    
        #Homesection1 {
            width: 100%;
            background-size:cover;
            background-position: center 300px;
            
    
           
        }
        #centre {
           
            width: 100%;
            gap: 0rem;
           
        }
        #agence360 {
           
            width: 494px;
            margin-top: 13rem;
           
        }
    
        #divparagraph {
           
            margin-top: 4rem;
            
        
        }
        #DevisButton {
          
            margin: 4rem  0rem 0rem 0rem ;
            
        }
      
        #BarreLink {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            margin-top: 4rem;
            border-radius:0rem 0rem 30rem 30rem ;
        }
      
      
        #BarreLink div:hover {
    
            padding: 0rem 0rem 1rem 0rem ;
            transition:ease-in-out 0.3s;
            
        }
        .divider {
            width:0.1rem;
            height: 4rem;
            background-color: #fff;
            border-radius: 2rem;
            
            
        }
      
       
       
    
        #Homesection2 p {
            width: 97%;
           

        }
        #Homesection2 div img {
            display: block;
          /*width: 570px;  */
          margin: auto;
          
        }
       
        #Homesection3 #Services {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            gap:1rem;
        
        } 
    
        
    #Homesection3 #Services div{
        
        width:45%;
        margin-bottom: 100px;
     
    }
    
   
    
  
    #Homesection4 p {
        width: 95%;
    }  
    #Homesection5 {
    
        column-gap:6rem;
        row-gap: 50px;
    
    
    }
     #Homesection6 {
            
            margin-bottom: 4rem;;
        }
     #Homesection6Div{
            
            padding: 5rem 0rem;
        }
    
       
       
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
    
    #HomePage { 
        text-align: center;
    }

    #HomePage h1 {
        color: #E8C133;
       /* font-size: 2.5rem;
        line-height: 2.5rem;*/
        font-size:calc(0.5rem + 8vw) ;
        line-height:calc(0.75rem + 8vw) ;
        font-weight: 700;
    
    }
    #HomePage span {
    
        color:#E8C133;
        /*font-size: 1.5rem;
        line-height: 1.5rem;*/
        font-size:calc(0.5rem + 4vw) ;
        line-height:calc(0.75rem + 4vw) ;
    }
    #HomePage p {

        font-size:calc(1rem + 0.25vw) ;
        line-height:calc(1.5rem + 0.25vw) ;
        
      }
    
    #Homesection6Div div:first-child {
        width: 100%;
        font-size: 3rem;
        color:#E8C133;
        font-weight: 700;
        text-align: center;
        padding: 1rem 1rem;
        
    }
    #HomePage br {
        display: contents;
    }
 #Homesection1 {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-image: none;
       
    
}  

#centre {
    
    width: 100%;

}
#agence360 {
  
   /* width: 75%;*/
   width: 287.9px;
   margin-top: 6rem;
 
}
/* #divparagraph {
   width: 345px;
    width: 356px;
    width: 80%;
    gap: 0.75rem;
    

}*/

#DevisButton {
 
    padding: 1rem 2.5rem;
    margin: 0rem;
}  
#BarreLink {

    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 0.7rem;
   
}
#BarreLink div {
    width: 30%; 
}

#BarreLink img {
    width: 100%;

}

#BarreLink div:hover {

    padding: 0rem 0rem 0.5rem 0rem ;
    transition:ease-in-out 0.3s;
    
}
.divider {
    width:1px;
    height: 3rem;
    background-color: #fff;
    border-radius: 2rem;
   
}
.skewX {
    display: block;
    transform: skewX(5deg);
    /*z-index:-1;*/
}
.skewX5p {
    display: block;
    transform: skewX(-5deg);
   /* z-index: -1;*/
}

#Homesection2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0rem;
   
    
}

.bloc{
    width: 100%;
}



#Homesection2 p {
    width: 100%;  
 
 
}
#Homesection2 div img {
    display: block;
    /*width: 70%; 
    width: 290px;
    margin: 0rem auto 0rem auto;*/
   
}


#Homesection3 h1 br {
   display: contents;
}

#Homesection3 #Services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    gap: 12px;

} 

#Homesection4 {
   
    flex-direction: column-reverse;
    
}
.un{

    margin-left: 0rem;
    margin-top: 2rem;
}
.deux {
    display: none;
}

#Homesection4 span {
    font-size: 1.75rem;
    display: block;
    margin-bottom: 25px;
}


#Homesection4 p {
    width: 100%;
}

#Homesection6 {
    
     padding: 4rem 0rem 0rem 0rem;
     display: flex;
     justify-content: center;
     align-items: center;
 
 }

#Homesection6Div {
    flex-direction: column;
    margin: 4rem 1rem;
    text-align: center;
    font-weight: 100;
    padding-top: 3rem;
    
    
}

#Homesection6Div div:last-child{
    width: 100%;
    padding:0rem 1rem;
}

#Homesection6Div div:last-child{
    font-size: 1rem;
    text-align: center;
    padding:0rem 0rem 3rem 0rem;
    line-height: auto;
    width: 93%;
}


}