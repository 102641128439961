#errorpage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8C133;
    width: 100vw;
    height: 100vh;

}
#errorpage img {
    opacity: 0.5;
}
#errorpage span {
    position:absolute;
    top: center;
    left: center;

    font-size: 4rem;
    font-weight: 900;
    text-align: center;

}