#serv {

    color: transparent;
  
}

#blocun {

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;

}

#blocun div {
    width: 50%;
}

#blocun h1 ,#Servicessection3 h1{

    color: #E8C133;
}


#Servicessection2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 8rem ;
    margin-bottom: 8rem;

}

#Servicessection3 {
    
   /* background-color:#3E3E3E;*/
    background:linear-gradient(#3E3E3E,#323232);
    
}


#divSS3 {
    display: flex;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    margin: 0 auto;
    
    
}

#divSS3 p {
    width: 80%;
}

#Servicessection4 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #000;
   /* background: linear-gradient(#E8C133,#8f730f);
    background-color: #E8C133;*/
    padding: 8rem 0rem;
}

 #Servicessection4 .DevisButton {
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    border-radius: 2rem;
    margin: 2rem 0 0 0;
    color: #000;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8C133;
  }

#Servicessection4 div[class='container'] {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    gap: 10rem;
}

#Servicessection4 div[class='container'] div{
    width: 50%;
    height: 100%;
} 

#Servicessection4 div[class='container'] div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    
}

#Servicessection4 img {
    width:100%;
}

#Servicessection4 h1 { 
    color: #E8C133;
} 

#Servicessection4 p {
    color: #fff;
    font-size: 24px;
    width: 80%;
}


/*Media queries desktop*/
@media screen and (min-width:1440px){
    #Servicessection1 {
        padding: 0rem 2rem;
    }
    #serv {

        font-size: 12.5rem;
        font-weight: 900;
        letter-spacing:1.2rem;
        /*text-shadow: #E8C133 0px  0px 10px*/
        -webkit-text-stroke: 2px #E8C133;
        text-align: end;
        padding: 6rem 0rem 2rem 0rem;
    }

    #blocun div:last-child{
        display: flex;
       
    }
    #blocun div img {
       margin: auto;
       height: 390px;
    }
  
    #blocun p {
    
        width: 90%;
        margin-bottom: 1rem;
    }

    #Servicessection2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4rem 2rem;
        margin-top: 8rem ;
        margin-bottom: 8rem;
    
    }
    #divSS3 img {
        margin: auto 0 auto auto;
    }
    
}
/*Media queries Tablettes*/
@media screen and (width<1440px) and (width>600px) {
    #Servicessection1 {
        padding: 0rem 0rem;
    
    }
    #serv {

        font-size: 8rem;
        font-weight: 900;
        
        -webkit-text-stroke: 2px #E8C133;
        text-align: center;
        padding: 6rem 0rem 2rem 0rem;
    }

    #blocun {

    display: flex;
  
    justify-content: center;
    align-items: center;
    gap: 2rem;

    position: relative;

   background-image: url(../../assets//img/Main_colore.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 453px;

    }

    #blocun div {
        width: 100%;
    }
    #blocun div:first-child{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        padding: 1rem 2rem;
        background-color: rgba(0, 0, 0, 0.80);
        height: 453px;
    }
    #blocun div:last-child {
        display: none;
    }

    #blocun h1 {
    
        font-size:3rem;
        line-height: 3rem;
        color: #E8C133;
        margin-bottom: 2rem;
    }
    #blocun p {
    
        width: 80%;
        margin-bottom: 1rem;
    }

    #Servicessection2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem 1rem;
    margin-top: 8rem ;
    margin-bottom: 8rem;

}

    #Servicessection3 {
        padding: 0rem 2rem;
    }
    #Servicessection3 h1 {

        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 2rem;
      
    }
    #Servicessection3 h1 br {
         display: contents;
    }

    #divSS3 {
        display: flex;
       /* flex-direction: column-reverse;*/
        align-items: center;
        padding: 0rem 0rem;
        gap: 2rem;
        
        background-image: url(../../assets/img/levelamain.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;


        
    }
    
    #divSS3 div  {
        width:100%;
    }

     #divSS3 div:first-child{
         padding: 15rem 2rem;
         background-color: rgba(0, 0, 0, 0.80);

      }

    #divSS3 div:last-child{
        display: none;
     }

    #divSS3 h1 {
     
        margin-bottom: 1rem;
    }
    #Servicessection4 {
       
        padding: 4rem 0rem;
    }
    #Servicessection4 div[class='container'] {
       /* flex-direction: column-reverse;*/
        justify-content:  center;
        align-items: center;
        gap: 4rem;

        background-image: url(../../assets//img/BesoinApp.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 350px;
       
    }

     #Servicessection4 div[class='container'] div:first-child {
         height: 350px;
        background-color: rgba(0, 0, 0, 0.80);
     }
    #Servicessection4 div[class='container'] div{
        width: 100%;
        display: flex;
        /*flex-direction: column;*/
        align-items: center;
        justify-content:space-between;
        
    } 
    #Servicessection4 div[class='container'] div h1{
        font-size: 3.5rem;
        line-height: 3rem;
       margin-bottom: 2rem;
    } 
    #Servicessection4 div[class='container'] div h1 br{
       display: contents;
     }

    #Servicessection4 div[class='container'] div:last-child {
        display: none;
      }
     
    .DevisButton {
  
        margin: 4rem 0rem 0rem 0rem;
        font-weight: 900;
      }
     
      .DevisButton:hover {
        filter: invert(100%);
      }
    
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
    #Servicessection1 {
        margin-top: 5rem;
        padding: 0rem 1rem;
    }
    #serv {

        font-size: 4rem;
       
        font-weight: 700;
        -webkit-text-stroke: 1px #E8C133;
        text-align: center;
        margin-bottom: 2rem;
    }

    #blocun {
        flex-direction: column;
        gap: 0rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    
    }
   
    #blocun div {
        width: 100%;
        height: 100%;
        display: contents;
    
    }
 
    #blocun div img {
      margin:0rem 0rem 25px 0rem;
      background-size: cover;
    order: -1;
    }

    #blocun h1 {

       /* font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: 4rem;*/
        order: -2;
        
    }

    #blocun p {
    
        width: 100%;
        margin-bottom: 25px;
        
    }

    #Servicessection2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;
        margin-top: 1rem ;
        margin-bottom: 1rem;
    
    }
    

    #Servicessection3 {
    
        padding: 2rem 1rem;
     }

    #Servicessection3 h1 {

        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        
    }
    #Servicessection3 h1 br {
        display: contents;
    }
    
    #divSS3 {
        flex-direction: column;
        gap: 2rem;
        padding: 0rem 0rem;
        
    }

    #divSS3 div p {
        width: 100%;
    }

    #Servicessection4 {
    
        padding: 4rem 0rem;
    }
    
    #Servicessection4 div[class='container'] {

        flex-direction: column-reverse;
        justify-content:  center;
        align-items: center;
        gap: 2rem;
        padding: 2rem 1rem;
    }

    #Servicessection4 div[class='container'] div{
        width: 100%;
       
    } 

    #Servicessection4 div[class='container'] div{
       
        align-items: center;
        justify-content:center;
        
    }

    #Servicessection4 h1 {

        font-size: 2.1rem;
        line-height: 2.1rem;
        margin-bottom: 1rem;
    }
    #Servicessection4 h1 br {
        display: contents;
    }

    .DevisButton {
  
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:transparent;
        padding: 1rem 3rem;
        border-radius: 2rem;
        margin: 4rem 0rem 0rem 0rem;
        color: #fff;
        cursor: pointer;
        background-color: #000;
      }

      .DevisButton:hover {
        filter: invert(100%);
      }


}