.App_dev_titre {
    font-size: 8rem;
    font-weight: 900;
    text-align: right;
    margin-top: 10rem;
}

.App_dev_titre :nth-child(1) {
    color: #000;
    -webkit-text-stroke: 2px #E8C133;
    padding-right: 2rem;

}

.App_dev_titre :nth-child(2) {
    color: #fff;
    
}

.Dev_mobile {
    display: flex;

    gap: 1rem;
    justify-content: center;

}

.Dev_mobile .bloc span {
    display: block;
    margin-bottom: 1rem;
}

.Dev_mobile .bloc span:nth-child(1){
    font-size: 3.5rem;
    line-height: 3rem;
}

.Dev_mobile .bloc span:nth-child(2){
    font-size: 5rem;
    line-height: 4rem;
    font-weight: 900;
    
}
.Dev_mobile .bloc span:nth-child(3){
    font-size: 4rem;
    line-height: 3rem;
    font-weight: 900;
    color: #E8C133;

    margin-bottom: 4rem;
}

.Dev_mobile .bloc  p {
    width: 85%;
    margin-bottom: 4rem;
}

.heading_section {
    text-align: center;
    margin-bottom: 4rem;
}

.heading_section span:nth-child(1) {

    font-size: 3.5rem;
    line-height: 3rem;
    display: block;

}

.heading_section span:nth-child(2) {

    font-size:5rem;
    line-height: 5rem;
    color: #E8C133;
    font-weight: 900;
}

.heading_section p {
    margin: 1rem auto 0rem auto;
    width: 50%;

}
#ideation {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem ;
}

.blochalf {
    width: 45%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 1rem;
}

.step {
    width: 70%;
   
}

.step span {
    font-size: 2rem;
   

}

.step span:nth-child(1) {
    color: #E8C133;
    margin-right: 1rem;
   

}
.step span:nth-child(2) {
    font-weight: 900;
   
}

.step p {
    margin-top: 2rem;
}
.ide div span {

    color: #E8C133;
    font-size: 2rem;
    line-height: 2.5rem;
    display: block;
    margin-bottom: 1rem;

}

.bloc100 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    gap: 2rem;
   
}

.maitre_mot {
    display: flex;
    gap: 4rem;
    
}

.Card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden ;

}


.bloc:has(div:nth-child(1) div[class='ToggleClass']) + .Card img[class='roster_img img1'] {
    position: absolute;
    width: 75%;

    left: 12%;

    opacity: 1;
   
}

.Card img[class='roster_img img1']{
    position: absolute;
    width: 75%;

    left: 12%;

    opacity: 1;
    transition: all ease-in-out 0.3s;
}

 .Card img[class='roster_img img2'],.Card img[class='roster_img img3'] {
    position: absolute;
    width: 75%;

    left: -100%;

    opacity: 0;
    transition: all ease-in-out 0.3s;
}

.bloc:has(div:nth-child(1) div[class='paragraphClass']) + .Card img[class='roster_img img1'] {
    position: absolute;
    width: 75%;

    left: 12%;

    opacity: 1;
   
}

.bloc:has(div:nth-child(2) div[class='paragraphClass']) + .Card img[class='roster_img img2'] {
    position: absolute;
    width: 75%;

    left: 12%;

    opacity: 1;
   
}

.bloc:has(div:nth-child(3) div[class='paragraphClass']) + .Card img[class='roster_img img3'] {
    position: absolute;
    width: 75%;

    left: 12%;

    opacity: 1;
   
}



.maitre_mot div img {
   width: 100%;

}




.construisons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;

}
.construisons_heading {
   
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: #E8C133;
    text-align: center;


 
}

.construisons div span:nth-child(1) {
    display: block;

}

.construisons div span:nth-child(2) {
    color: #fff;
    font-weight: 700;
    font-size: 4rem;
    line-height: 3rem;
 
}

.construisons p {
    width: 40%;
    margin-inline: auto;
    text-align: center;
}

.section_BG {
    background-image: url(../../assets//img/Section_BG.png);
    background-repeat: no-repeat;
    background-size: cover;
   

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;

    padding:4rem 1rem;

    border-radius: 2rem;

}

.section_BG h1 {
    font-size: 3rem;
    color: #000;
}

.section_BG div {
    color: #000;
    font-weight: 700;
    background-color: #fff;
    padding: 1rem 3rem;
    border-radius: 5rem;

    cursor: pointer;

    transition: all ease-in-out 0.5s;
}

.section_BG div:hover {

   filter: invert(1);

}

@media screen and (width<1440px)  and (width>600px) { 
    .App_dev_titre {
        font-size: 8rem;
        line-height: 8rem;
        margin-bottom: 2rem;
        font-weight: 900;
        text-align: center;
        margin-top: 6rem;
    
    }
    .App_dev_titre span {
        display: block;
    }
  

}

@media screen and (max-width:600px){ 

    .App_dev_titre {
        font-size: 5rem;
        line-height: 5rem;
        margin-top: 6rem;
        margin-bottom: 1rem;
        text-align: center;
      
    }
    .App_dev_titre span {
        display: block;
        
    }

    .Dev_mobile {
        display: flex;
        flex-direction: column-reverse;
    
        gap: 2rem;
        justify-content: center;
        text-align: center;
    }

    .Dev_mobile .bloc span:nth-child(1){
        font-size: 3rem;
        line-height: 3rem;
    }

    .Dev_mobile .bloc span:nth-child(2){
        font-size: 4.5rem;
        line-height: 4rem;
       
        
    }

    .Dev_mobile .bloc span:nth-child(3){
        font-size: 3rem;
        line-height: 3rem;
       
    }
    
    .Dev_mobile .bloc p  {
        width: 100%;
    }
    .Dev_mobile .bloc {
       
       display: flex;
       flex-direction: column;
       align-items: center;
    }

    .heading_section span:nth-child(1) {

        font-size: 3.5rem;
        line-height: 4rem;
        font-weight: 900;
        display: block;
    
    }
    
    .heading_section span:nth-child(2) {
    
        font-size:5rem;
        line-height: 5rem;
        color: #E8C133;
        font-weight: 900;
    }

    .bloc100,.blochalf{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .blochalf div img {
        transform: rotate(90deg);
    }

    .step {
        width: 100%;
        text-align: center;
    } 
    .step span {
        font-size: 2.2rem;
       
    
    }
    .step p {
       width: 90%;
       margin: 2rem auto 0rem;
    }

    .ide div {
        width: 60%;
        margin: auto;
    }

    .heading_section.excellence span {
        font-size: 3rem;
    
    }

   .heading_section span:nth-child(1) {
    line-height: 4rem;

   }
   
   .heading_section span:nth-child(2) {
    line-height: 3rem;
    
   }

   .heading_section.excellence p {
    width: 80%;
    }

    .maitre_mot {
        display: flex;
        gap: 4rem;
        flex-direction: column-reverse;
        
    }
    .construisons_heading {

        font-size: 3rem;
         line-height: 4rem;

    }
    
    .construisons p {
        width: 95%;
        margin-inline: auto;
        text-align: center;
    }

    .section_BG h1 {
        font-size: 2rem;
        color: #000;
    }
    
}