#SignOut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    text-align: center;
    z-index: 2;
    
}

#SignOut * {
    display: block;
    transition: ease-in-out 0.3s ;
}
#SignOut div {
    width: 12.5rem;
    border-radius: 2rem ;
}

#SignOut span {
    

    color: #000;
}

#nom_profil {

    font-size: 1.75rem;
    font-weight: 700;

}
#mail_profil {

    font-size: 1.25rem;
    font-style: italic;

}
#reglages {

    border-radius: 2rem;
    outline :none;
    background-color: #000;
    color: #fff;
    padding: 1rem 6rem;
    font-size: 1.5rem;

}
#deconnexion {

    border-radius: 2rem;
    outline :none;
    background-color: transparent;
    border: #000 3px solid;
    padding: 1rem 2rem;
    font-size: 1.5rem;

}

#reglages:hover,#deconnexion:hover{
    filter: invert(100%);
    padding: 1rem 5rem;
}


/*Media queries desktop*/
@media screen and (min-width:1440px){
    #SignOut div {
        width: 12.5rem;
    }

    #deconnexion:hover{
        
        padding: 1rem 3rem;
    }
    #reglages:hover {

        padding: 1rem 6.5rem;

    }
        
    
   
 
 }
 /*Media queries Tablettes*/
 @media screen and (max-width:1440px) and (min-width:600px) {
    #SignOut div {
        width: 10rem;
    }
    #nom_profil {

        font-size: 1.5rem;
        font-weight: 700;
    
    }
    #mail_profil {
    
        font-size: 1rem;

    
    }
    #reglages {
    
        padding: 1rem 5rem;
        font-size: 1rem;
    
    }
    #deconnexion {
    
        font-size: 1rem;
    
    }

    #deconnexion:hover{
        
        padding: 1rem 3rem;
    }
    #reglages:hover {

        padding: 1rem 5.5rem;

    }
      
    
 
 
 
 }
 /*Media queries Mobile*/
 @media screen and (max-width:600px) {
    #SignOut {
        gap: 0.75rem;
    }
   
    #SignOut div {
        width: 8rem;
    }
    #nom_profil {

        font-size: 1.5rem;
        font-weight: 700;
    
    }
    #mail_profil {
    
        font-size: 1rem;

    
    }
    #reglages {
    
        padding: 1rem 5rem;
        font-size: 1rem;
    
    }
    #deconnexion {
    
        
        font-size: 1rem;
    
    }
    #deconnexion:hover{
        
        padding: 1rem 3rem;
    }
    #reglages:hover {

        padding: 1rem 5.5rem;

    }
      
    
    
 
 
 }