
#Card{
    display: inline-block;
    /*width: 30%;
    padding: 1rem;*/
}

#Card h1{
    font-size: 2rem;
    margin-bottom: 1rem;
}

#Card p{
    /*width: 70%;*/
    width: 100%;
}

/*Media queries desktop*/
@media screen and (min-width:1440px){
    #Card{
        width: 25%;
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       /*margin: 0rem 2rem;
       text-align: center;*/
    }
    #Card div{
        margin-bottom: 1rem;
    }
    #Card p{
        margin-top: 1rem;
    }
}
/*Media queries Tablettes*/
@media screen and (width<1440px) and (width>600px) {
   
    #Card{
        /*width: 40%;
        width :250px;*/
        width :40%;
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       margin: 0rem 2rem;
       /*text-align: center;*/
    }
    #Card div{
        margin-bottom: 1rem;
    }
    #Card p{
        width: 100%;

    }
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {
   
    #Card{
        width: 100%;
       display: flex;
       flex-direction: column;
       align-items: center;
       margin: 0rem ;
       padding: 0rem;
       /**/text-align: center;
      
    }
    #Card div {
        margin-bottom: 2rem;
    }
    #Card h1 {
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    #Card p{
        /*width: 90%;*/
        width: 100%;
        font-size: 14px;

    }
}
