.inputfailed {
    box-shadow: red 1px 1px 1rem;
    transition: ease-in-out 0.3s;
}

#MenuRightDIvDevis{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: #000;
    z-index: 2;   
    
}

#MenuRightDIvDevis form span{
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;

}

#Nom,#Phone,#email_devis{
    outline: none;
    border:none;
    padding: 1.5rem 1rem 1.5rem 2rem;
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    
}

#Nom {
   border-radius: 1rem 1rem 0rem 0rem;
}

#textarea {
    border-radius: 0rem 0rem 1rem 1rem;
    resize: none;
    outline: none;
    border:none;
    padding: 1.5rem 2rem 1.5rem 2rem;
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    
} 

#Envoyer {
    outline: none;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    padding: 1rem 0rem ;
    width: 100%;
    border-radius: 0.7rem;
    font-size: 1rem;
    text-align: center;
}

#Envoyer:hover {
    filter: invert(100%);
}

#closer{
    align-self: center;
   /* align-self: flex-end;
   background-color: red;
     width: 15px;
    height: 15px;
    padding: 1rem;
    border-radius: 50%;
    text-align: center;*/

    display: none;
    position: absolute;
    top: -10px;
    /*top: -10px;
    right: -25px;*/
    

}

#closer img{
    display: block;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.1s;
}

#closer:hover img{
  
    width:90%;
    height:90%;
    transition: all ease-in-out 0.1s;

}


/*Media queries desktop*/
@media screen and (min-width:1440px){
   
    

    #Nom,#Phone,#email_devis{
       
        padding: 1.5rem 1rem 1.5rem 2rem;
        margin-bottom: 0.5rem;
        
    }

    textarea {
        
        height: 14rem;
        
    } 

    .MenuRightDivreveal {
    
        position: relative;
        left:0;
        transition: ease-in-out 0.3s;
        opacity: 1;
    
    }
    
    .MenuRightDivhide{
        
        position: relative;
       
        left: -100vh;
        opacity:0;
        pointer-events: none;
        transition: ease-in-out 0.3s;
        
        
    }
    
    
}
/*Media queries Tablettes*/
@media screen and (max-width:1440px) and (min-width:600px) {
  
 
    #Nom,#Phone,#email_devis{
        
        padding: 1.5rem 1rem 1.5rem 2rem;
        margin-bottom: 0.5rem;
        
    }

    #textarea {
        
        height: 12rem;
        
    } 
    .MenuRightDivreveal {
    
        position: relative;
        left:0;
        transition: ease-in-out 0.3s;
        opacity: 1;
    
    }
    
    .MenuRightDivhide{
        
        position: relative;
       
        left: -100vh;
        opacity:0;
        pointer-events: none;
        transition: ease-in-out 0.3s;
        
        
    }
    
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {

    #closer{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MenuRightDivreveal {
    
        position: relative;
        top:0;
        transition: ease-in-out 0.3s;
        opacity: 1;
    
    }
    
    .MenuRightDivhide{
        
        position: relative;
       
        top: -100vh;
        opacity:0;
        pointer-events: none;
        transition: ease-in-out 0.3s;
        
        
    }

    #Nom,#Phone,#email_devis{
      
        padding: 1rem 1rem 1rem 2rem;
        margin-bottom: 0.5rem;
        
    }

    #textarea {
        
        height: 10rem;
        
    } 
    #MenuRightDIvDevis form span{
        display: block;
        visibility: hidden;
       ;
    
    }
}


/*Media queries Mobile landscape oriented*/
@media screen and (min-width:600px) and (max-height:600px) and (orientation:landscape) {

    #MenuRightDIvDevis *{

    
     margin:0.2rem 0rem 0rem;
     width: 300px;
     height: fit-content;
     font-size:2.5vh;
        
    }
    #MenuRightDIvDevis input,#MenuRightDIvDevis textarea {
        padding: 1rem;
    }

    #MenuRightDIvDevis span,#MenuRightDIvDevis form span {
        display: none;
    }

}