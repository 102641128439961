.Depliable{ 
    background-color :#222121;
    padding :2rem;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    margin-top:2rem;
    margin-bottom:2rem;
  
  
    width:100%;
    border-radius:1rem;
    color:#E7C133;
   
  
  
  }
.heading {
    display:flex;
    align-items:center;
    justify-content:space-between;
  
    gap:2rem;
    width:100%;
    font-size:2rem;
    font-weight:900;
  }
  
.titre{

    display:flex;
    align-items:center;
    justify-content:center;
  
    gap:2rem
  }


  @media screen and (width<1440px)  and (width>600px) {

  }
  @media screen and (max-width:600px){

    .heading {
        display:flex;
        align-items:center;
        justify-content:space-between;
      
        gap:2rem;
        width:100%;
        font-size:1.5rem;
        font-weight:900;
      }

      .bloc div:nth-child(2) div[class='paragraphClass'] {
  
        height: 15rem;
     
      }
      
      .bloc div:nth-child(3) div[class='paragraphClass'] {
  
        height: 10rem;
     
      }
      

  }