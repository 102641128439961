.GP {
    margin-top: 5rem;
}

.yellow {
    color: #E8C133;
}

#GPslide1 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
  
    margin: 2rem 0rem;
}
#GPslide1 img {
    margin: 0 auto;
}

#CTA {
    width: 30rem;
}
#CTA div{
    width: 18rem;
    margin-bottom: 1rem;
}

.GPsect3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.GPsect3 div:nth-child(1){
    text-align: center;
     width: 70%;
    
}
.GPsect3 div:nth-child(2){
    text-align: center;
     width: 80%;
    
}

#GPsect4div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items:flex-start;
    gap: 2rem;
    margin-top: 4rem;

    position: relative;

    /*overflow-x: hidden;*/
    overflow-x: scroll;
}
#Gpsect4div::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
#Gpsect4div::-webkit-scrollbar-track{
    background: transparent;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
::-webkit-scrollbar-track{
    background: transparent;
}



#ArrowLeft {
  
    position: absolute;
    left: 0rem;
    top: 6rem;
    z-index: 2;
 
}

#ArrowRight{
  
    position: absolute;
    right: 0rem;
    top: 6rem;
    z-index: 2;
   

} 

#ArrowRight:hover img, #ArrowLeft:hover  img{
    width: 80px;
    height: 80px;
    transition: all ease-in-out 0.2s;
}
#ArrowRight img,#ArrowLeft img{
    width:50px;
    height: 50px;
    transition: all ease-in-out 0.2s;
}

#GPsect5div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:flex-start;
    gap: 2rem;
    margin-top: 4rem;
}
#GPsect6div {

    display:flex;
    color:#000

}
#GPsect6div div:nth-child(1) {
    background:#E8C133;
    padding: 2rem 5rem
}

/*Media queries mobile*/
@media screen and (max-width:600px){

    #GPslide1 {
        display: flex;
        flex-direction: column;
        gap: 2rem 0rem;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      
        margin: 2rem 0rem;
    }

    #GPslide1 h1, #GPslide1 p{
      text-align: center ;
    }

    /*#GPslide1 h1{
        width: 80%;
        margin: 1rem auto;
      }*/

     /*#GPslide1 img {
       margin: 2rem 0;
        width: 100%;
    } */
    #CTA {
        width:100%; 
       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #CTA div{

        margin: 0 auto 1rem;
    
    }

    .GPsect3 div:nth-child(1){
        text-align: center;
         width: 100%;
        
    }
    .GPsect3 div:nth-child(2){
        text-align: center;
         width: 100%;
        
    }
    

    #GPsect4div {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items:flex-start;
        gap: 2rem;
        margin-top: 4rem;
    
        position: relative;
    
        /*overflow: hidden;

        width: 500px;*/


    }



    #GPsect6div {

        display:flex;
        flex-direction: column-reverse;
        color:#000
    
    }
    #GPsect6div div:nth-child(1) {
        background:#E8C133;
        padding: 1rem 1rem
    }
    
}