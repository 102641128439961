
footer {
    border-top: #3E3E3E 10px solid;
}

#bloc1 {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   gap: 1rem;
   flex-wrap: wrap;
   padding: 2rem 2rem;
}

#bloc1div1 {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#bloc1div1 div:first-child {

    display: block;
    padding: 0rem 0rem 1rem 0rem;
}

#bloc1 div ul {
    
    list-style: none;
    padding: 1rem 0rem;
    
}

#bloc1 div ul li {
    
    font-weight: 100;
    padding-bottom: 0.5rem;
    
}

#bloc1div1 #RS {

    display: flex;
    padding: 0.5rem 0rem 0rem 0rem;
   
}
#bloc1div1 #RS img {
   cursor: pointer;
}

#bloc1div1 #RS img:not(:last-child) {
    padding-right: 1.3rem;
}

#bloc1div2 {

    width: 50%;
 }

 #col2 h1 {
    color: #E8C133;
    font-size: 30px;
    font-weight: bolder;
    line-height: 1.8rem;
    margin-bottom: 0.5rem;
 }

 #col2 span{
    font-size: 0.8rem;
    font-weight: 300;
    display: block;
   margin-bottom: 1rem;
   
 }

 #col2 div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0rem;

 }

 #col2 div input{
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0rem 1rem;
    font-size: 1rem;
    color:#fff;
    width: 15rem;
    height: 3rem;
    border-radius: 3rem;
    
 }

 #col2 div input::placeholder{
    font-size: 12px;
   
    font-weight: 100;
    padding-left: 0.7rem;
 
    
 }

 #col2 div button{
    outline: none;
    border: 1px solid #E8C133;
    font-size: 1rem;
    color: #E8C133;
    background-color: transparent;
    width: 8rem;
    height: 3rem;
    border-radius: 3rem;
    cursor: pointer;
 
 }

 #col2 p{
    font-size: 12px;
    font-weight: 100;
    width:48%;
    line-height: auto;
    margin-top: 1rem;

 }

#bloc2 {
    background-color: #3B3B3B;
    
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    padding: 2rem;
}

#bloc2 ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    
}

#bloc2 ul li:not(:last-child){
    border-right:1px solid aliceblue;
}

#bloc2 ul li {
    padding: 0rem 0.3rem;
}

/*Media queries desktop*/
@media screen and (min-width:1440px) { 
    #bloc1 {
        padding: 4rem 0rem 4rem 5rem;
    }
    #bloc1div2 {
  
      width: 45%;
    
   }
}
/*Media queries Tablettes*/
@media screen and (width<1440px) and (width>600px) {

    #bloc1 {
        padding: 3rem 2rem;
        display: flex;
        justify-content: flex-start;
        
    }
    #bloc1 div:nth-child(1) {
      width:50%
    }

    #bloc1 div:last-child {
      width:100%
    }

    #col2 div input{
    
      padding: 0rem 1rem;
      
      width: 20rem;
    
      
   }

   #col2 p{
   
      width:100%;
      
  
   }
  
  
   
}
/*Media queries Mobile*/
@media screen and (max-width:600px) {

#bloc1 {
   gap: 0rem;
   padding: 4rem 2rem 4rem;
}

#bloc1 div ul {
   display: none;
}

#col2 h1 {
    margin-top: 4.5rem;
    margin-bottom: 0.5rem;
    font-size: 30px;
 }
 #col2 span {

    margin-bottom: 0rem;
    font-size: 14px;
 }

 #col2 div {

    margin: 2rem 0rem;
    width: 100%;
    padding: 0rem 12%;
    gap: 1.375rem;
 } 

 #col2 div input {

   width: 100%;
   margin: 0;
   padding: 0rem 1.5rem;
 } 
 #col2 div input::placeholder {
   color: #ffffff48;
    text-align: center;
    padding-left: 0;

  }
 #col2 div button {

    width: 100%;
  } 

  #col2 p {
    font-size: 14px;
    text-align: center;
    /*width: 88%;*/
    width: 296px;
    margin-top: 0rem;
    line-height: auto;
  }
 

 #bloc1div1, #bloc1div2 {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
 }
 #col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }
 #col2 div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }

 #bloc2 {
    display: flex;
    justify-content: center;
    align-items: center;
 }

 #bloc2 ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }

 #bloc2 ul li {
    padding: 0;
 }

 #bloc1 ul li {
   margin: 1rem;
   
 }

 #bloc2 ul li:not(:last-child) {
    border-right: 0px solid aliceblue;
    margin-top: 0.1rem;
    font-size: 14px;
}

#bloc2 ul li:last-child {
    font-size: 14px;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 2.4rem;

}


}